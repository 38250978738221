/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 40,
    height= 47,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 612 792" style="enable-background:new 0 0 612 792;" xml:space="preserve">
<style type="text/css">
	.st0{fill:none;}
	.st1{fill:#FFFFFF;}
</style>
<path class="st0" d="M306,330.3L306,330.3L306,330.3L306,330.3L306,330.3z"/>
<path d="M482.4,336.5c-5.2-87.6-74.8-158.6-162.4-164.9c-3.9-0.3-7.7-0.5-11.6-0.5c-56.8-1.1-104.3,21.6-140.5,67.1
	c-53.5,67.3-50.8,159.6,4.6,225.2c30.9,36.6,61.8,73.2,92.8,109.8c13.5,15.8,26.9,31.7,40.8,48c1.4-1.6,2.4-2.7,3.4-3.8
	c42.2-49.8,84-99.8,126.6-149.2C468.9,430.2,485.3,386.7,482.4,336.5z"/>
<g>
	<path class="st1" d="M328.2,334.9l-36.8-5.2c-38.1-5-49.9-12.8-49.9-31c0-19.1,20.4-31.5,63.2-31.5c40.2,0,69.8,11.4,71.7,44h28.4
		c-0.7-47.3-39.5-67.6-97.9-67.6c-53.5,0-94.4,17.4-94.4,57.1c0,33,14.8,45.5,74.3,54.4l36.8,5c51,7.7,61.1,14.8,61.1,34.7
		c0,16.7-13.7,36.4-74,36.4c-42.5,0-75.2-17.4-78.3-48h-29.3c4.8,50.1,45.8,71.9,106.4,71.9c68.7,0,103.9-25.3,103.9-62.8
		C413.5,356.6,393.7,343,328.2,334.9"/>
</g>
</svg>`;
    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 40,
      height: 47,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 60,
      height: 67,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 60,
      height: 67,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
